.Skill {
  position: relative;
  margin: 10px;
  padding: 6px;
  border-radius: 9px;
}
.Skill__title {
  transform: rotate(90deg);
  position: absolute;
  right: 30px;
  font-size: 1.8rem;
  font-weight: bold;
}
.Skill__titleVertical {
  font-size: 1.7rem;
  font-weight: bold;
  margin: 5px 10px;
}

.Skill__text {
  padding-right: 50px;
  margin-left: 3vw;
}

@media screen and (max-width: 500px) {
  .Skill__title {
    position: initial;
    transform: rotate(0deg);
  }
  .Skill__text {
    padding-right: 0;
  }
}
