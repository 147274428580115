.blog {
  margin: 9px;
  display: flex;
  flex-direction: row;
}
.blog__img {
  height: 20vh;
  border-radius: 5px;
}
.blog__text {
  flex-grow: 1;
}
