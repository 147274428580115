@import url(https://fonts.googleapis.com/css2?family=Syne:wght@500&display=swap);
body {
  background-color: #fafafa;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --background: #fafafa;
  --white: #ffffff;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app__container {
  width: 100%;
  background-color: #fafafa;
  display: grid;
  place-items: center;
}

.idk__container {
  font-family: "Inter", sans-serif;
}
.idk__container {
  width: 1150px;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  margin: 200px auto 0;
  padding: 20px;

  display: flex;
  flex-flow: row wrap;
}
.idk {
  font-size: 100px;
  font-weight: 800;
  letter-spacing: -0.02em;
  text-align: left;
  z-index: 10;
  position: relative;
  line-height: 0.6em;
  pointer-events: none;
  flex: 1 1 100%;

  border: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

.grid-item {
  flex: 1 1 50%;
  min-height: 440px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 25px 50px 55px;

  cursor: pointer;
}
.grid-item-mini {
  min-height: 180px;
  padding: 0 50px 30px;
}
.grid-item-mini h1 {
  font-size: 36px;
}
.grid-item-text {
  margin: 0 auto 40px;
  width: 80%;
  z-index: 10;
  max-width: 500px;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.grid-items-feature-first {
  flex: 1 1 100%;
}
.grid-item-third {
  flex: 1 1 30%;
}
.grid-item-quarter {
  flex: 1 1 25%;
}
.grid-item-fifth {
  flex: 1 1 20%;
}
.grid-items-peak {
  margin-top: 150px;
}
.square {
  display: flex;
  flex-direction: column;

  font-size: 12px;
  color: black;
  border-radius: 4px;
}
.square div {
  height: 70px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(253, 244, 241);
}
.square div:hover {
  background-color: rgb(243, 229, 224);
  overflow: hidden;
}
.square__one {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.square__two {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.blog-banner-content {
  margin: 5px 0 40px;
}

.grid-item-header {
  font-size: 45px;
  line-height: 1.3em;
  margin: 60px auto 0.65em;
  transition: -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 680px;
  z-index: 10;
  letter-spacing: -0.3px;
}

@media (max-width: 600px) {
  .idk {
    font-size: 16vw;
  }
  .grid-items-peak {
    margin-top: 60px;
  }
  .grid-item-header {
    font-size: 33px;
  }
}
@media (max-width: 800px) {
  .idk__container {
    padding: 20px;
    margin-top: 130px;
  }
  .post-preview,
  .post-preview:nth-last-child(1),
  .post-preview:nth-last-child(2) {
    flex: 1 1 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .grid-item {
    min-height: 400px;
    padding: 5px 20px 30px;
  }
  .grid-item-mini {
    min-height: 90px;
  }
  .grid-item-mini h1 {
    font-size: 28px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.header {
  background-color: #fafafa;
  color: #212121;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* position: sticky; */
  top: 0;
  z-index: 999;
}

.invert {
  background-color: #212121;
  color: #fafafa;
}

.header__logo {
  height: 6vh;
  padding: 10px;
  margin-top: 1vw;
  margin-left: 4vw;
}

.header__links {
  margin-right: 4vw;
}
.header__links span {
  font-family: "Syne", sans-serif;
  font-weight: 500;
  padding: 15px;
  font-size: 1.3rem;
}

.blogs {
  margin: 0;
}
.blogs__heading {
  margin-top: 0;
  margin-bottom: 2vh;
  padding: 10px;

  background-color: #212121;
  color: #fafafa;

  font-size: 2rem;
  font-family: "Syne", sans-serif;
  text-align: center;
}

.blogs__container {
  width: 100%;
  background-color: #fafafa;
  display: grid;
  place-items: center;
}

.blog {
  margin: 9px;
  display: flex;
  flex-direction: row;
}
.blog__img {
  height: 20vh;
  border-radius: 5px;
}
.blog__text {
  flex-grow: 1;
}

.MainPageCard__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5vh;
}

.MainPageCard__container h1 {
  font-size: 50px;
  letter-spacing: 1px;
  padding: 0px 0 12px 0;
  margin: 0;
}
.MainPageCard__container h4 {
  font-family: "Syne", sans-serif;
  letter-spacing: 1px;
  padding: 0px 0 12px 0;
  margin: 0;
  max-width: 600px;
}

.MainPageCard__image img {
  border: solid 20px rgba(0, 0, 0, 0.8);
  width: 250px;
  height: 250px;
}
.MainPageCard__buttons {
  margin-top: 15px;
}

.MainPageCard__button {
  font-size: 11px;
  text-transform: uppercase;
  padding: 0px 12px !important;
  letter-spacing: 0.5px;
  height: 30px;
  line-height: 28px !important;
  margin-right: 2px;
  background: none;
  border: 1px solid #fff;
  opacity: 0.7;
}
.MainPageCard__button:hover {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  .MainPageCard__container {
    flex-direction: column-reverse;
    padding: 25px 15px;
  }
  .MainPageCard__container h1 {
    margin-top: 12px;
  }
  .MainPageCard__container h1 {
    font-size: 35px;
  }
  .MainPageCard__container h4 {
    font-size: 12px;
  }
  .MainPageCard__container {
    margin-top: 1vh;
  }
}

.Instructions {
  position: relative;
  border: 2px dashed #90a4ae;
  padding: 6px 5px;
  margin: 3px;
  margin: 0 30vw;
  margin-top: 40px;
}
.Instructions__text {
  padding: 6px;
}
.Instructions__small {
  position: absolute;
  top: -12px;
  background-color: var(--background);
  padding: 0 2px;
  font-weight: 500;
}

@media (max-width: 800px) {
  .Instructions {
    margin: 0 10vw;
  }
}

.ContactPageCard__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .ContactPageCard__container div {
    flex-direction: column;
  }
}

.GrowBar h3 {
  margin: 0;
}
.GrowBar__title {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}
.GrowBar__subTitle {
  display: block;
  font-size: 0.8em;
}
.GrowBar__lines {
  font-weight: bolder;
}
.GrowBar__restLines {
  font-weight: lighter;
}

.SkillsPageCard__container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  padding: 15px;
  margin: 0 6vw;
}
.SkillsPageCard__anchor {
  font-size: 16px;
  text-decoration: underline;
}
.SkillsPageCard__heading {
  font-size: 2.1rem;
  font-weight: 600;
}
.SkillsPageCard__header {
  align-self: center;
  text-align: center;
}
.SkillsPageCard__header span {
  font-size: 1.1rem;
}

@media (max-width: 800px) {
  .SkillsPageCard__container {
    margin: 0 1vw;
  }
}

.PageCard__container {
  min-height: 93vh;
  width: 100%;
}

@media screen and (max-width: 500px) {
}

.Skill {
  position: relative;
  margin: 10px;
  padding: 6px;
  border-radius: 9px;
}
.Skill__title {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  position: absolute;
  right: 30px;
  font-size: 1.8rem;
  font-weight: bold;
}
.Skill__titleVertical {
  font-size: 1.7rem;
  font-weight: bold;
  margin: 5px 10px;
}

.Skill__text {
  padding-right: 50px;
  margin-left: 3vw;
}

@media screen and (max-width: 500px) {
  .Skill__title {
    position: static;
    position: initial;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .Skill__text {
    padding-right: 0;
  }
}

.Tag__container {
  display: flex;
  align-items: center;
  margin: 5px 0;
  margin-left: 3vw;

  font-family: "Syne", sans-serif;
  text-align: start;
}
.Tag__dateContainer h2 {
  font-size: 20px;
  margin: 0;
  margin-right: 15px;
}
.Tag__textContainer h3,
.Tag__textContainer p {
  margin: 0;
  max-width: 250px;
}
.Tag__textContainer p {
  margin: 3px 0;
}
.Tag__subsubTitle {
  font-size: 0.9rem;
}
.Tag__where {
  color: rgb(43, 43, 43);
}

@media (max-width: 600px) {
  .Tag__container {
    margin-left: 5vw;
  }
}

