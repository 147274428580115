.Tag__container {
  display: flex;
  align-items: center;
  margin: 5px 0;
  margin-left: 3vw;

  font-family: "Syne", sans-serif;
  text-align: start;
}
.Tag__dateContainer h2 {
  font-size: 20px;
  margin: 0;
  margin-right: 15px;
}
.Tag__textContainer h3,
.Tag__textContainer p {
  margin: 0;
  max-width: 250px;
}
.Tag__textContainer p {
  margin: 3px 0;
}
.Tag__subsubTitle {
  font-size: 0.9rem;
}
.Tag__where {
  color: rgb(43, 43, 43);
}

@media (max-width: 600px) {
  .Tag__container {
    margin-left: 5vw;
  }
}
