.MainPageCard__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5vh;
}

.MainPageCard__container h1 {
  font-size: 50px;
  letter-spacing: 1px;
  padding: 0px 0 12px 0;
  margin: 0;
}
.MainPageCard__container h4 {
  font-family: "Syne", sans-serif;
  letter-spacing: 1px;
  padding: 0px 0 12px 0;
  margin: 0;
  max-width: 600px;
}

.MainPageCard__image img {
  border: solid 20px rgba(0, 0, 0, 0.8);
  width: 250px;
  height: 250px;
}
.MainPageCard__buttons {
  margin-top: 15px;
}

.MainPageCard__button {
  font-size: 11px;
  text-transform: uppercase;
  padding: 0px 12px !important;
  letter-spacing: 0.5px;
  height: 30px;
  line-height: 28px !important;
  margin-right: 2px;
  background: none;
  border: 1px solid #fff;
  opacity: 0.7;
}
.MainPageCard__button:hover {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  .MainPageCard__container {
    flex-direction: column-reverse;
    padding: 25px 15px;
  }
  .MainPageCard__container h1 {
    margin-top: 12px;
  }
  .MainPageCard__container h1 {
    font-size: 35px;
  }
  .MainPageCard__container h4 {
    font-size: 12px;
  }
  .MainPageCard__container {
    margin-top: 1vh;
  }
}
