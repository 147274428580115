.SkillsPageCard__container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  padding: 15px;
  margin: 0 6vw;
}
.SkillsPageCard__anchor {
  font-size: 16px;
  text-decoration: underline;
}
.SkillsPageCard__heading {
  font-size: 2.1rem;
  font-weight: 600;
}
.SkillsPageCard__header {
  align-self: center;
  text-align: center;
}
.SkillsPageCard__header span {
  font-size: 1.1rem;
}

@media (max-width: 800px) {
  .SkillsPageCard__container {
    margin: 0 1vw;
  }
}
