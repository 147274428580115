.ContactPageCard__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .ContactPageCard__container div {
    flex-direction: column;
  }
}
