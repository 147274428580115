.blogs {
  margin: 0;
}
.blogs__heading {
  margin-top: 0;
  margin-bottom: 2vh;
  padding: 10px;

  background-color: #212121;
  color: #fafafa;

  font-size: 2rem;
  font-family: "Syne", sans-serif;
  text-align: center;
}

.blogs__container {
  width: 100%;
  background-color: #fafafa;
  display: grid;
  place-items: center;
}
