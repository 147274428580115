a {
  text-decoration: none;
  color: inherit;
}

.header {
  background-color: #fafafa;
  color: #212121;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* position: sticky; */
  top: 0;
  z-index: 999;
}

.invert {
  background-color: #212121;
  color: #fafafa;
}

.header__logo {
  height: 6vh;
  padding: 10px;
  margin-top: 1vw;
  margin-left: 4vw;
}

.header__links {
  margin-right: 4vw;
}
.header__links span {
  font-family: "Syne", sans-serif;
  font-weight: 500;
  padding: 15px;
  font-size: 1.3rem;
}
