:root {
  --background: #fafafa;
  --white: #ffffff;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app__container {
  width: 100%;
  background-color: #fafafa;
  display: grid;
  place-items: center;
}

.idk__container {
  font-family: "Inter", sans-serif;
}
.idk__container {
  width: 1150px;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  margin: 200px auto 0;
  padding: 20px;

  display: flex;
  flex-flow: row wrap;
}
.idk {
  font-size: 100px;
  font-weight: 800;
  letter-spacing: -0.02em;
  text-align: left;
  z-index: 10;
  position: relative;
  line-height: 0.6em;
  pointer-events: none;
  flex: 1 100%;

  border: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

.grid-item {
  flex: 1 50%;
  min-height: 440px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 25px 50px 55px;

  cursor: pointer;
}
.grid-item-mini {
  min-height: 180px;
  padding: 0 50px 30px;
}
.grid-item-mini h1 {
  font-size: 36px;
}
.grid-item-text {
  margin: 0 auto 40px;
  width: 80%;
  z-index: 10;
  max-width: 500px;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.grid-items-feature-first {
  flex: 1 100%;
}
.grid-item-third {
  flex: 1 1 30%;
}
.grid-item-quarter {
  flex: 1 1 25%;
}
.grid-item-fifth {
  flex: 1 1 20%;
}
.grid-items-peak {
  margin-top: 150px;
}
.square {
  display: flex;
  flex-direction: column;

  font-size: 12px;
  color: black;
  border-radius: 4px;
}
.square div {
  height: 70px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(253, 244, 241);
}
.square div:hover {
  background-color: rgb(243, 229, 224);
  overflow: hidden;
}
.square__one {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.square__two {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.blog-banner-content {
  margin: 5px 0 40px;
}

.grid-item-header {
  font-size: 45px;
  line-height: 1.3em;
  margin: 60px auto 0.65em;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateZ(0);
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 680px;
  z-index: 10;
  letter-spacing: -0.3px;
}

@media (max-width: 600px) {
  .idk {
    font-size: 16vw;
  }
  .grid-items-peak {
    margin-top: 60px;
  }
  .grid-item-header {
    font-size: 33px;
  }
}
@media (max-width: 800px) {
  .idk__container {
    padding: 20px;
    margin-top: 130px;
  }
  .post-preview,
  .post-preview:nth-last-child(1),
  .post-preview:nth-last-child(2) {
    flex: 1 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .grid-item {
    min-height: 400px;
    padding: 5px 20px 30px;
  }
  .grid-item-mini {
    min-height: 90px;
  }
  .grid-item-mini h1 {
    font-size: 28px;
  }
}
