.Instructions {
  position: relative;
  border: 2px dashed #90a4ae;
  padding: 6px 5px;
  margin: 3px;
  margin: 0 30vw;
  margin-top: 40px;
}
.Instructions__text {
  padding: 6px;
}
.Instructions__small {
  position: absolute;
  top: -12px;
  background-color: var(--background);
  padding: 0 2px;
  font-weight: 500;
}

@media (max-width: 800px) {
  .Instructions {
    margin: 0 10vw;
  }
}
