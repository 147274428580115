.GrowBar h3 {
  margin: 0;
}
.GrowBar__title {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}
.GrowBar__subTitle {
  display: block;
  font-size: 0.8em;
}
.GrowBar__lines {
  font-weight: bolder;
}
.GrowBar__restLines {
  font-weight: lighter;
}
